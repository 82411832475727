/*
 * Copyright © 2016 ООО «Верме»
 *
 * Всевозможные полезности для отправики XMLHttpRequest'ов.
 * Лежит отдельно от models/core, чтоб мог быть подключен одним из первых.
 *
 * refactoring 2020
 */

Model.openXHR = function(method, path, async) {
  if (async === undefined) {
    async = true;
  }

  if ('xhr_path_prefix' in localStorage) {
    path = localStorage.xhr_path_prefix + path;
  }

  var xhr = new XMLHttpRequest();
  xhr.open(method, path, async);
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

  return xhr;
};

// `{num:1, str:'qwe', arr:[1,2,3]}` --> `num=1&str=qwe&arr=%5B1%2C2%2C3%5D`
Model.joinGetParams = function(params) {
  return Object.keys(params)
    .map(key => {
      let value = params[key];

      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');
};

Model.getCsrfToken = function() {
  var match = document.cookie.match(/csrftoken=([^;]*)/);

  if (!match) {
    throw new Error('no CSRF-token in cookies');
  }

  return match[1];
};

Model.addCSRFToken = function(xhr) {
  var token = Model.getCsrfToken();
  xhr.setRequestHeader('X-CSRFToken', token);
};

/**
 * Метод для добавления заголовков в XMLHttpRequest запрос.
 * @param xhr {XMLHttpRequest} Объект запроса.
 * @param headers {object} Параметры заголовковдля запроса.
 */
Model.setHeadersToXHR = function(xhr, headers) {
  Object.keys(headers).forEach(headerType => {
    xhr.setRequestHeader(headerType, headers[headerType]);
  });
};

/**
 * Метод для удаления заголовков из XMLHttpRequest запроса.
 * @param xhr {XMLHttpRequest} Объект запроса.
 * @param headers {object} Параметры заголовковдля запроса.
 */
Model.removeHeadersFromXHR = function(xhr, headers) {
  Object.keys(headers).forEach(headerType => {
    xhr.setRequestHeader(headerType, '');
  });
};

// Ошибка запроса к серверу.
// error.type - тип ошибки, единственное обязательное поле; значения:
//   'xhr-error'  - ошибка отправки запроса, дополнительно есть error.xhr;
//   'json-error' - ошибка парсинга ответа, дополнительно есть error.xhr и error.exception с ошибкой парсинга;
//   'error'      - ошибка от сервера (когда если что-то пошло не так)
//   'user-error' - ошибка от сервера (когда запрос пользователя нельзя выполнить, потому что последний ввёл что-то не то)
//   'multiplayer-error' - ошибка от сервера (когда два пользователя пытались одновременно что-то редактировать)
// error.xhr - объект запроса (для 'xhr-error' и 'json-error')
// error.exception - ошибка при парсинге ответа (для 'json-error')
// error.message - готовое текстовое сообщение
// error.name - текстовый код ошибки (типа 'book_shift:shift_not_found')
// error.args - объект с аргументами для отображения сообщения об ошибке (типа {wrong_orgunit_code: 'bla-bla'})
// error.long_description - длинное сообщение об ошибке (готовым текстом), которое (скорее всего) будет скрыто в попапе под спойлером
// У всех ошибок кроме 'xhr-error' и 'json-error' всегда должен быть как минимум 'message' или 'name'.
Model.XHRError = function ModelXHRError(type, params) {
  this.type = type;

  for (var name in params) {
    this[name] = params[name];
  }
};

Model.XHRError.prototype.toString = function() {
  switch (this.type) {
    case 'xhr-error':
      return getText('xhr_error_xhr');
    case 'json-error':
      return getText('xhr_error_json');
    default:
      return (this.message || this.name) + (this.long_description ? `: ${this.long_description}` : '');
  }
};

// Отправляет JSON-запрос с методом `method` на адрес `path`.
// `data` - данные для отправки или null
// В ответ ожидает тоже JSON.
// При удачном ответе вызывает `onOk(response_data)`
// При ошибке вызывает `onErr(error)`, где error - объект Model.XHRError
Model.xhr = function({ method, path, data, onOk, onErr, async, skipCsrf, headers }) {
  // дополнительные параметры
  if (method === 'GET') {
    if (!data) {
      data = {};
    }
    data['t'] = Date.now();
  }

  // обработка данных для отправки
  if (method === 'GET') {
    path += `?${Model.joinGetParams(data)}`;
    data = null;
  } else {
    // если это объект и не FormData, пойдёт как JSON
    if (data && typeof data == 'object' && !(data instanceof FormData)) {
      data = JSON.stringify(data);
    }
  }

  // сам запрос
  var xhr = Model.openXHR(method, path, async);

  if (!skipCsrf) {
    Model.addCSRFToken(xhr);
  }

  if (headers) {
    Model.setHeadersToXHR(xhr, headers);
  }

  xhr.onload = function() {
    var do_default;

    try {
      var res = JSON.parse(xhr.responseText);
    } catch (ex) {
      do_default =
        onErr &&
        onErr(
          new Model.XHRError('json-error', {
            exception: ex,
            xhr,
          }),
        );

      if (do_default !== false) {
        Model._netErr(path, `${ex.name}: ${ex.message}\nIn:\n${xhr.responseText}`);
      }

      return;
    }

    if (res.error) {
      if (typeof res.error == 'string') {
        res.error = { message: res.error };
      }

      var error = new Model.XHRError(res.result, res.error);

      if (Model._resErrHook(path, error)) {
        return;
      }

      do_default = onErr && onErr(error);

      if (do_default !== false) {
        Model._netErr(path, error.toString());
      }

      return;
    }

    if (onOk) {
      onOk(res);
    }
  };

  xhr.onerror = function() {
    var do_default = onErr && onErr(new Model.XHRError('xhr-error', { xhr }));

    if (do_default !== false) {
      Model._netErr(
        path,
        `XHR status: ${xhr.status}, text: ${xhr.statusText}, response: ${xhr.responseText}`,
      );
    }
  };

  xhr.send(data);

  return xhr;
};

Model._resErrHook = function(path, error) {
  if (error.name === 'not_authorized') {
    location.href = '/auth/login/';
    window.dispatchEvent(new CustomEvent('app:auth:reload'));

    return true;
  }

  return false;
};

Model._netErr = function(path, msg) {
  console.error(`On path: ${path}\n${msg}`);
  Model.error(`On path: ${path}\n${msg}`);
};

Model.error = function(msg) {
  var xhr = Model.openXHR('POST', '/applogs/');
  Model.addCSRFToken(xhr);
  xhr.send(`ERROR: ${msg}`);
};

Model.openTabWithGET = function(path, data) {
  if (data !== null && data !== undefined) {
    path += `?${Model.joinGetParams(data)}`;
  }
  Util.openTabWithGET(path);
};

Model.openTabWithPOST = function(path, data) {
  var params = { csrfmiddlewaretoken: Model.getCsrfToken() };

  for (var key in data) {
    params[key] = typeof data[key] == 'string' ? data[key] : JSON.stringify(data[key]);
  }

  Util.openTabWithPOST(path, params);
};
