/*
 * Copyright © 2016 ООО «Верме»
 *
 * Ловит ошибки из window.onerror, отправляет их на сервер.
 * Для работы нужен models/core/xhr.js
 *
 * refactoring 2020
 */

window.addEventListener('error', function(e) {
  if (
    (e.message === 'Script error.' && e.filename === '') ||
    e.message.includes('Possible side-effect in debug-evaluate')
  ) {
    return; //похоже, это ошибка во внешнем плагине, игнорируем её
  }

  if (e.detail && e.detail.message === 'rsQueryMemoryLimitExceeded') {
    if (document.body.$('.big-popup-wrap')) {
      return;
    }

    UI.showPopup({
      title: 'Ошибка',
      body: `
        <div style="width: 410px">
          Не хватает ресурсов для отображения отчета. Пожалуйста, дождитесь ответа страницы или повторите попытку позднее
        </div>
      `,
    });

    return;
  }

  let errorText = '';

  if (e.error) {
    errorText = `${e.message}\n${e.error.stack}`;
  } else if (e.message || e.filename) {
    errorText = `${e.message} in ${e.filename}:${e.lineno}:${e.colno}`;
  } else {
    errorText = `unknown error happened (${!!e.error})`;
  }

  UI.alertAppError();
  Model.error(errorText);
});
