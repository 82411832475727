function loginForm(data) {
  // title, body, afterRender, onSubmit, onAbort, onApply
  if (!data) {
    data = {};
  }

  var div = document.createElement('div');
  div.classList.add('big-popup-wrap');
  div.classList.add('pp-closer');
  div.innerHTML =
    '' +
    '<div class="pp-closer">' +
    '<div class="pp-closer">' +
    '<div id="main_popup_content"></div>' +
    '</div>' +
    '</div>';
  document.body.appendChild(div);
  document.querySelector('#main_popup_content').innerHTML =
    `${'' +
      '<div class="huge-logo"></div>' +
      '<div class="pp-title">' +
      '<div id="pp_title_content">'}${data.title || ppTitleLogin}</div>` +
    `<div id="pp_close" class="pp-closer">&#10005;</div>` +
    `</div>` +
    `<div class="pp_main">${data.body || ''}</div>`;
  document.querySelector('.big-popup-wrap').addEventListener('click', closePopup);
  document.querySelector('#pp_close').addEventListener('click', closePopup);

  if (data.afterRender) {
    data.afterRender();
  }

  if (data.onSubmit) {
    document.querySelector('.big-popup-wrap').addEventListener('click', submitPopup);
  }

  if (data.onApply) {
    document.querySelector('.big-popup-wrap').addEventListener('click', applyPopup);
  }

  function closePopup(e) {
    if (e && !e.target.classList.contains('pp-closer')) {
      return;
    }

    var continueClosing = true;

    if (data.onAbort) {
      continueClosing = data.onAbort();
    }

    if (continueClosing) {
      if (document.querySelector('.big-popup-wrap')) {
        document
          .querySelector('.big-popup-wrap')
          .parentNode.removeChild(document.querySelector('.big-popup-wrap'));
      }
    }
  }

  function submitPopup(e) {
    if (e && !e.target.classList.contains('pp-submiter')) {
      return;
    }

    var continueClosing = data.onSubmit();

    if (continueClosing) {
      closePopup();
    }
  }

  function applyPopup(e) {
    if (e && !e.target.classList.contains('pp-apply')) {
      return;
    }
    data.onApply();
  }
}

var queueTimeout;

function enterQueue() {
  document.querySelector('.login-popup').style.pointerEvents = 'none';
  queueTimeout = setTimeout(function() {
    document.querySelector('.login-popup .error').style.color = 'inherit';
    document.querySelector(
      '.login-popup .error',
    ).innerHTML = `<center>${checkDataLogin}</center><br><center><i style="color:#0f9a5e" class="fa fa-spinner fa-spin"></i></center><br>`;
  }, 300);
}

function exitQueue() {
  clearTimeout(queueTimeout);
  document.querySelector('.login-popup').style.pointerEvents = '';
  document.querySelector('.login-popup .error').style.color = 'red';
  document.querySelector('.login-popup .error').textContent = '';
}

loginForm({
  title: titleLoginForm,
  body: document.querySelector('#login_popup').innerHTML,
  onSubmit() {
    var login = document.querySelector('.login-popup .login').value;
    var password = document.querySelector('.login-popup .password').value;

    var clientValidationError = false;

    if (!login) {
      document.querySelector('.login-popup .login').classList.add('validation-error');
      document.querySelector('.login-popup .error').innerHTML = messageTextLogin;
      clientValidationError = true;
    }

    if (!password) {
      document.querySelector('.login-popup .password').classList.add('validation-error');
      document.querySelector('.login-popup .error').innerHTML = messageTextLogin;
      clientValidationError = true;
    }

    if (clientValidationError) {
      return;
    }
    enterQueue();
    Model.xhr({
      method: 'POST',
      path: '/auth/login/',
      data: {
        login,
        password,
      },
      onOk(data) {
        location.href = '/';
      },
      onErr(error) {
        exitQueue();
        document.querySelector('.login-popup .error').innerHTML =
          error.type === 'user-error' ? error.message : error.toString();
        document.querySelector('.login-popup .login').classList.add('validation-error');
        document.querySelector('.login-popup .password').classList.add('validation-error');

        // для user-error отключаем дефолтный обработчик ошибок с логгированием
        return error.type !== 'user-error';
      },
    });

    return false;
  },
  afterRender() {
    var buttonClickToggle = document.querySelector('.toggle-login-form');

    if (buttonClickToggle) {
      buttonClickToggle.onclick = function() {
        var el = document.querySelector('.show-form-wrap');
        var isOpened = !el.classList.contains('expanded');
        el.classList.toggle('expanded', isOpened);
        document.querySelector('.login-form').classList.toggle('hidden', !isOpened);
        document.querySelector('.login-form input').focus();
      };
    }

    document.querySelector('.pp-title .pp-closer').style.display = 'none';

    document.querySelector('.login-popup').onclick = function(e) {
      if (e.target.classList.contains('field')) {
        document.querySelector('.login-popup .error').innerHTML = '';
        e.target.classList.remove('validation-error');
      }
    };

    document.querySelector('.login-popup').onkeydown = function(e) {
      if (e.keyCode === 13) {
        document.querySelector('.login-popup .pp-submiter').click();
      }
    };

    document.querySelector('.login').onchange = function(e) {
      var str = e.target.value;
      var isValid;
      var reg;

      if (~str.indexOf('@')) {
        reg = new RegExp(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
        isValid = reg.test(str);
      } else {
        reg = new RegExp(/[a-zA-Z-а-яА-Я]/);
        isValid = !reg.test(str);
      }

      e.target.classList.toggle('validation-error', !isValid);
    };

    var resetPassword = document.querySelector('.login-popup .restore-password');

    if (resetPassword) {
      resetPassword.onclick = function() {
        window.location.href = '/password_reset/';
      };
    }
  },
  onAbort() {
    return false;
  },
});
